import initialState from './initialState';
import equApi from '../api/equipement';
import userApi from '../api/user';
import $ from 'jquery';
import CryptoAES from 'crypto-js/aes';
import CryptoENC from 'crypto-js/enc-utf8';
import config from '../api/config';
import { BrowserRouter } from 'react-router-dom';

var newigonerCount = 0;

global.sessions = {
	//global varibale declared for maintaining data from sessions tab customer name
	customerName: CryptoAES.decrypt(
		getCookie('customerName').toString(),
		config.encrypt_key_1 + config.encrypt_key_2
	).toString(CryptoENC),

	//global varibale declared for maintaining data from sessions tab announcement
	announcement: CryptoAES.decrypt(
		getCookie('announcement').toString(),
		config.encrypt_key_1 + config.encrypt_key_2
	).toString(CryptoENC),

	//flag kept by default flase so that popup won't keep on displaying on every refresh
	announcement_flag: true,

	//global varibale declared for maintaining data from sessions tab featurelist value coloumn
	featureList_value: CryptoAES.decrypt(
		getCookie('featureList_value').toString(),
		config.encrypt_key_1 + config.encrypt_key_2
	).toString(CryptoENC),

	//global varibale declared for maintaining data from sessions tab featurelist property coloumn
	featureList_property: CryptoAES.decrypt(
		getCookie('featureList_property').toString(),
		config.encrypt_key_1 + config.encrypt_key_2
	).toString(CryptoENC),

	//flag kept by default flase so that analytics link won't keep on displaying on every refresh
	analyticsLink_flag: true,

	//global varibale declared for maintaining data from sessions tab userID
	userId: CryptoAES.decrypt(getCookie('userId').toString(), config.encrypt_key_1 + config.encrypt_key_2).toString(
		CryptoENC
	),

	sessionDuration: 14400,
	refreshDuration: 14400,
};

function getCookie(c_name) {
	if (document.cookie.length > 0) {
		let c_start = document.cookie.indexOf(c_name + '=');
		if (c_start != -1) {
			c_start = c_start + c_name.length + 1;
			let c_end = document.cookie.indexOf(';', c_start);
			if (c_end == -1) {
				c_end = document.cookie.length;
			}
			return unescape(document.cookie.substring(c_start, c_end));
		}
	}
	return '';
}

function clearAllCookies() {
	document.cookie.split(';').forEach(function (c) {
		document.cookie = c.replace(/^ +/, '').replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
	});
}

export default function applicationReducers(state = initialState, action) {
	let timeOutForCookie = 1440;
	let createCookie = function (name, value, min) {
		let expires;
		if (min) {
			let date = new Date();
			date.setTime(date.getTime() + min * 60 * 1000);
			expires = '; expires=' + date.toGMTString();
		} else {
			expires = '';
		}
		document.cookie = name + '=' + value + expires + '; path=/';
	};

	switch (action.type) {
		case 'LOGIN_SUCCESS': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('LOGIN_SUCCESS', action.data);
			newState.portfolio = [];
			console.log('LOGIN_SUCCESS', newState);
			newState.token = action.data.data.sessionToken;
			newState.sessionToken = action.data.data.sessionToken;
			newState.refreshToken = action.data.data.refreshToken;
			newState.sessionDuration = action.data.data.sessionDuration;
			newState.refreshDuration = action.data.data.refreshDuration;
			newState.customerName = action.data.data.customerName;
			newState.announcement = action.data.data.announcement;
			newState.userId = action.data.data.firstName;
			if (action.data.data.featureList.length) {
				newState.featureList_value = action.data.data.featureList[0].value;
				newState.featureList_property = action.data.data.featureList[0].property;
			} else {
				newState.featureList_value = '';
				newState.featureList_property = '';
			}
			createCookie(
				'apiToken',
				CryptoAES.encrypt(newState.token, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);
			createCookie(
				'sessionToken',
				CryptoAES.encrypt(newState.sessionToken, config.encrypt_key_1 + config.encrypt_key_2),
				newState.sessionDuration
			);
			createCookie(
				'refreshToken',
				CryptoAES.encrypt(newState.refreshToken, config.encrypt_key_1 + config.encrypt_key_2),
				newState.refreshDuration
			);
			createCookie(
				'refreshExp',
				CryptoAES.encrypt(
					String(new Date((new Date().getTime() / 1000 + newState.refreshDuration) * 1000).getTime()),
					config.encrypt_key_1 + config.encrypt_key_2
				),
				newState.refreshDuration
			);

			createCookie(
				'customerName',
				CryptoAES.encrypt(newState.customerName, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);
			createCookie(
				'announcement',
				CryptoAES.encrypt(newState.announcement, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);
			createCookie(
				'featureList_value',
				CryptoAES.encrypt(newState.featureList_value, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);
			createCookie(
				'featureList_property',
				CryptoAES.encrypt(newState.featureList_property, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);
			createCookie(
				'userId',
				CryptoAES.encrypt(newState.userId, config.encrypt_key_1 + config.encrypt_key_2),
				timeOutForCookie
			);

			return newState;
		}

		case 'UPDATE_TOKENS': {
			const newState = JSON.parse(JSON.stringify(state));
			const refreshToken = CryptoAES.decrypt(
				getCookie('refreshToken').toString(),
				config.encrypt_key_1 + config.encrypt_key_2
			).toString(CryptoENC);
			const sessionToken = CryptoAES.decrypt(
				getCookie('sessionToken').toString(),
				config.encrypt_key_1 + config.encrypt_key_2
			).toString(CryptoENC);

			userApi.patchNewToken(sessionToken, refreshToken, global.sessions.sessionDuration).then((response) => {
				newState.token = response.data.sessionToken;
				newState.sessionToken = response.data.sessionToken;
				newState.sessionDuration = response.data.sessionDuration;
				createCookie(
					'apiToken',
					CryptoAES.encrypt(newState.sessionToken, config.encrypt_key_1 + config.encrypt_key_2),
					newState.sessionDuration
				);
				createCookie(
					'sessionToken',
					CryptoAES.encrypt(newState.sessionToken, config.encrypt_key_1 + config.encrypt_key_2),
					newState.sessionDuration
				);
				createCookie(
					'refreshToken',
					CryptoAES.encrypt(newState.refreshToken, config.encrypt_key_1 + config.encrypt_key_2),
					newState.refreshDuration
				);
			});
			return newState;
		}

		case 'COUNT_UPDATE': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('COUNT_UPDATE', action.data);
			newState.counter = action.data;
			console.log('COUNT_UPDATE', newState.counter);
			return newState;
		}

		case 'LOAD_LOGIN': {
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 200);
			return state;
		}

		case 'SETINTERVAL': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.interval = action.data;
			return newState;
		}

		case 'LOG_OUT': {
			const newState = initialState;
			console.log('newState', newState);
			const sessionToken = CryptoAES.decrypt(
				getCookie('sessionToken').toString(),
				config.encrypt_key_1 + config.encrypt_key_2
			).toString(CryptoENC);

			clearInterval(window.updateTokenInterval);
			userApi.logout(sessionToken);
			clearAllCookies();

			$('.loaderPosition').addClass('hidden');
			return newState;
		}

		case 'SETINTERVAL2': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.interval2 = action.data;

			return newState;
		}

		case 'CLEAR': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('Clear', newState.trendData, newState.waveData);
			newState.trendData = {};
			newState.waveData = {};
			console.log('ClearAFTER', newState.trendData, newState);

			if (newState.interval !== -1) {
				clearInterval(newState.interval);
			}

			if (newState.interval2 !== -1) {
				clearInterval(newState.interval2);
			}

			newState.analysis = {};
			newState.reSetTheRefershingOnPowerMeteringPage = false;
			newState.newtrendDataForPowerMeter = { id: -1 };
			newState.chartLoaded = false;
			newState.interval = -1;
			newState.interval2 = -1;

			return newState;
		}

		case 'SET_SELECTED_NODE': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('SET_SELECTED_NODE', action);
			newState.reSetTheRefershingOnPowerMeteringPage = true;
			newState.newtrendDataForPowerMeter = { id: -1 };
			newState.selectedSerialNumber = action.selectedNode;
			newState.selectedName = action.nodeName;

			return newState;
		}
		case 'GET_NODE_LIST_FOR_POWER_METER_PAGE': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('GET_NODE_LIST_FOR_POWER_METER_PAGE', action.data);
			newState.portfolioChildString = JSON.stringify(action.data);

			if (action.data.nodes.length > 0) {
				action.data.nodes[0].title = action.data.name;
			}
			newState.portfolioChild = action.data;

			newState.facilityName = action.data.name;

			return newState;
		}

		case 'VIEW_EQUIPMENT_PORTFOLIO': {
			const newState = JSON.parse(JSON.stringify(state));
			for (let v = 0; v < action.equipments.sites.length; v++) {
				if (action.equipments.sites[v].name !== null) {
					for (let d = 0; d < action.equipments.sites[v].locations.length; d++) {
						action.equipments.sites[v].locations[d].title = action.equipments.sites[v].name;
					}
				}
			}
			console.log('VIEW_EQUIPMENT_PORTFOLIO', action, newState.portfolio);

			if (newState.portfolio.length === 0) {
				newState.portfolio = action.equipments.sites;
				newState.portfolioData = action.equipments.sites;
				newState.portfolio.forEach((site) => {
					site.iconStatus = false;
				});
			} else if (newState.portfolio !== null) {
				newState.portfolio.forEach((site) => {
					action.equipments.sites.forEach((actionSite) => {
						if (site.facilityName === actionSite.facilityName) {
							site.locations = actionSite.locations;
						}
					});
				});
			}
			console.log('VIEW_EQUIPMENT_PORTFOLIO After', newState.portfolio);
			// newState.token = getCookie('apiToken');
			//newState.token = CryptoAES.decrypt(getCookie('apiToken').toString(),config.encrypt_key_1+config.encrypt_key_2).toString(CryptoENC);
			return newState;
		}

		case 'CHANGE_ICON_STATUS': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('CHANGE_ICON_STATUS', newState.portfolio, action);
			newState.portfolio.forEach((site, v) => {
				if (site.facilityName === action.sites.facilityName) {
					site.iconStatus = site.iconStatus !== true;
				}
			});
			return newState;
		}

		case 'LOAD_EQUIPMENT_PORTFOLIO': {
			const newState = JSON.parse(JSON.stringify(state));
			console.debug('LOAD_EQUIPMENT_PORTFOLIO', action);
			$('.loaderPosition').removeClass('hidden');
			equApi
				.getEquipmentPortfolio(newState.token)
				.then((data) => {
					$(data.sites).each(function (index, value) {
						if (value.nodes.length > 0) {
							value.nodes[0].title = value.name;
						}
						if (value.locations.length > 0) {
							value.locations[0].title = value.name;
						}
					});
					newState.portfolio = data.sites;
					return newState;
				})
				.catch((error) => {
					return state;
				});

			return newState;
		}

		case 'VIEW_MOTOR_ANALYSIS': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.analysis = action.motorAnalysis;
			return newState;
		}

		case 'VIEW_MOTOR_ANALYSIS_MAIN': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.analysis = action.motorAnalysis;
			return newState;
		}

		case 'VIEW_POWER_METERING': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('actionactionactionactionaction', action);
			newState.metering = action.payload.metering;
			newState.selectedSerialNumber = action.payload.serialNumber;
			newState.selectedName = action.payload.name;
			return newState;
		}

		case 'VIEW_POWER_METERING_ADMIN': {
			console.log('VIEW_POWER_METERING_ADMIN', action.payload);
			const newState = JSON.parse(JSON.stringify(state));
			newState.metering = action.payload.metering;
			newState.selectedSerialNumber = action.payload.serialNumber;
			return newState;
		}

		case 'MOVE_SUBNODE_PAGE': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.serialNumberForSubnode = action.node.id;
			newState.topNavIconDecide = {};
			if (action.node.type === 'subNode') {
				newState.topNavIconDecide = true;
			} else {
				newState.topNavIconDecide = false;
			}
			return newState;
		}

		case 'REFS_SUBNODE_METER': {
			const newState = JSON.parse(JSON.stringify(state));
			$('.loaderPosition').addClass('hidden');
			if (action.metering.channelData !== null && action.metering.channelData !== undefined) {
				newState.subNodeData = action.metering;
				newState.serialNumberForSubnode = action.serialNumber;
			}
			return newState;
		}

		case 'REF_POWER_METERING': {
			const newState = JSON.parse(JSON.stringify(state));
			console.log('REF_POWER_METERING', action);
			newState.metering = action.metering;
			if (action.metering.equipmentType !== 'Rectifier') {
				if (action.metering.facilityPortfolio !== undefined && action.metering.facilityPortfolio !== null) {
					newState.portfolioChild = action.metering.facilityPortfolio;
				}

				if (newState.newtrendDataForPowerMeter.id === -1) {
					newigonerCount = 0;
					newState.newtrendDataForPowerMeter =
						action.metering.timestamp !== null && action.metering.timestamp !== undefined
							? {
									power: {
										mainPoints: [],
										missingPoints: [],
									},
									updatedOn: action.metering.displayTimestamp,
									id: parseInt(Math.random() * 1000),
							  }
							: {};
				} else {
					if (newigonerCount > 10) {
						var dataNew = JSON.parse(JSON.stringify(newState.newtrendDataForPowerMeter));
						if (action.metering.equipmentType == 'UtilityMain' && action.metering.rawPower !== undefined && action.metering.rawPower !== null){
							dataNew.power.mainPoints.push({
								date: parseInt(action.metering.timestamp) * 1000,
								power: { value: parseFloat(action.metering.rawPower) / 1000 },
							});
							dataNew.power.missingPoints.push({
								date: parseInt(action.metering.timestamp) * 1000,
								power: {
									value: parseFloat(action.metering.rawPower) / 1000,
									color: 'white',
									nullify: true,
								},
							});
						}else if (action.metering.power !== undefined && action.metering.power !== null) {
							dataNew.power.mainPoints.push({
								date: parseInt(action.metering.timestamp) * 1000,
								power: { value: parseFloat(action.metering.power) / 1000 },
							});
							dataNew.power.missingPoints.push({
								date: parseInt(action.metering.timestamp) * 1000,
								power: {
									value: parseFloat(action.metering.power) / 1000,
									color: 'white',
									nullify: true,
								},
							});
						}
						newState.newtrendDataForPowerMeter = dataNew;
					}

					newigonerCount++;
				}
			}
			newState.newtrendDataForPowerMeter.timezone = action.metering.timezone;
			console.log('action.metering', action.metering, newState.newtrendDataForPowerMeter);
			return newState;
		}

		case 'REF_MOTOR_ANALYSIS': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.analysis = action.motorAnalysis;
			return newState;
		}

		case 'REF_MOTOR_ANALYSIS_MAIN': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.analysis = action.motorAnalysis;
			return newState;
		}

		case 'UPDATE_WAVEFORM_CHART': {
			const newState = JSON.parse(JSON.stringify(state));
			$('.loaderPosition').addClass('hidden');
			let root_mean_square = function (ary) {
				let sum_of_squares = ary.reduce(function (s, x) {
					return s + x * x;
				}, 0);
				return Math.sqrt(sum_of_squares / ary.length);
			};

			let newharmonicsData = [];
			let harmonicsData = {
				category: [],
				data: [],
			};

			let voltageData = [
				{
					name: 'Va',
					data: [],
				},
				{
					name: 'Vb',
					data: [],
				},
				{
					name: 'Vc',
					data: [],
				},
			];

			let currentData = [
				{
					name: 'Ia',
					data: [],
				},
				{
					name: 'Ib',
					data: [],
				},
				{
					name: 'Ic',
					data: [],
				},
			];

			let fieldCurrentData = [
				{
					name: 'If',
					data: [],
				},
			];

			let fieldVoltageData = [
				{
					name: 'Vf',
					data: [],
				},
			];

			let vaCol = [];
			let vbCol = [];
			let vcCol = [];
			let laCol = [];
			let lbCol = [];
			let lcCol = [];
			let ifCol = [];
			let vfCol = [];
			let scaleValue = 1000;

			$.each(
				action.data.waveform,
				function (index, value) {
					if (value.time !== null && value.time !== undefined) {
						voltageData[0].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vA), color: '#4dac97' },
						});
						voltageData[1].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vB), color: '#EF4123' },
						});
						voltageData[2].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vC), color: '#4990e2' },
						});

						currentData[0].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iA), color: '#4dac97' },
						});
						currentData[1].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iB), color: '#EF4123' },
						});
						currentData[2].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iC), color: '#4990e2' },
						});
						if (
							action.data.equipmentType === 'DCMotor' ||
							action.data.equipmentType === 'SynchronousMotor'
						) {
							fieldCurrentData[0].data.push({
								date: parseFloat(value.time * scaleValue),
								yScale: { value: parseFloat(value.iF), color: '#4dac97' },
							});
							fieldVoltageData[0].data.push({
								date: parseFloat(value.time * scaleValue),
								yScale: { value: parseFloat(value.vF), color: '#4dac97' },
							});
							ifCol.push(parseFloat(value.iF));
							vfCol.push(parseFloat(value.vF));
						}

						vaCol.push(parseFloat(value.vA));
						vbCol.push(parseFloat(value.vB));
						vcCol.push(parseFloat(value.vC));
						laCol.push(parseFloat(value.iA));
						lbCol.push(parseFloat(value.iB));
						lcCol.push(parseFloat(value.iC));
					}
				}.bind(this)
			);

			let maxX = 0;
			let vaRMS = Math.round(root_mean_square(vaCol) * 100) / 100;
			let vbRMS = Math.round(root_mean_square(vbCol) * 100) / 100;
			let vcRMS = Math.round(root_mean_square(vcCol) * 100) / 100;
			let laRMS = Math.round(root_mean_square(laCol) * 100) / 100;
			let lbRMS = Math.round(root_mean_square(lbCol) * 100) / 100;
			let lcRMS = Math.round(root_mean_square(lcCol) * 100) / 100;
			let fvRMS = Math.round(root_mean_square(vfCol) * 100) / 100;
			let fcRMS = Math.round(root_mean_square(ifCol) * 100) / 100;

			if (isNaN(fvRMS) || fvRMS === null || fvRMS === undefined) {
				fvRMS = '';
			}
			if (isNaN(fcRMS) || fcRMS === null || fcRMS === undefined) {
				fcRMS = '';
			}
			if (isNaN(vaRMS) || vaRMS === null || vaRMS === undefined) {
				vaRMS = '';
			}
			if (isNaN(vbRMS) || vbRMS === null || vbRMS === undefined) {
				vbRMS = '';
			}
			if (isNaN(vcRMS) || vcRMS === null || vcRMS === undefined) {
				vcRMS = '';
			}
			if (isNaN(laRMS) || laRMS === null || laRMS === undefined) {
				laRMS = '';
			}
			if (isNaN(lbRMS) || lbRMS === null || lbRMS === undefined) {
				lbRMS = '';
			}
			if (isNaN(lcRMS) || lcRMS === null || lcRMS === undefined) {
				lcRMS = '';
			}

			for (let count = 1; count < 1000; count++) {
				let number;
				if (count.toString().length < 2) {
					number = '00' + count;
				} else if (count.toString().length < 3) {
					number = '0' + count;
				} else {
					number = count;
				}

				let key = 'harmonicFreq' + number;
				let value = 'harmonicValue' + number;

				if (action.data.harmonics[key] !== null && action.data.harmonics[key] !== undefined) {
					if (maxX < action.data.harmonics[key]) {
						maxX = action.data.harmonics[key];
					}

					let dataInRequiredChartFormate = {
						x: Math.round(action.data.harmonics[key] * 100) / 100,
						y: action.data.harmonics[value],
						color:
							action.data.harmonics['harmonicColor' + number] === undefined
								? '#4eac97'
								: '#' + action.data.harmonics['harmonicColor' + number],
						harmonicLFMult: action.data.harmonics['harmonicLFMult' + number],
					};

					harmonicsData.data.push(dataInRequiredChartFormate);

					/**
					 * For Temproary purpose
					 */
					newharmonicsData.push({
						date: dataInRequiredChartFormate.x,
						//date: new Date(),
						temperature: {
							value: dataInRequiredChartFormate.y,
							color: dataInRequiredChartFormate.color,
							width: 20,
							harmonicLFMult: dataInRequiredChartFormate.harmonicLFMult,
						},
						width: 20,
					});
				} else {
					break;
				}
			}
			harmonicsData.maxX = maxX;
			harmonicsData.lineFrequencyLocated = Math.round(action.data.lineFrequencyLocated * 100) / 100;

			console.log('harmonicsData', harmonicsData, newharmonicsData);
			console.log('VD', voltageData);
			console.log('CD', currentData);

			newState.waveData = {
				newharmonicsData,
				harmonicsData,
				voltageData,
				currentData,
				fieldCurrentData,
				fieldVoltageData,
				fvRMS,
				fcRMS,
				vaRMS,
				vbRMS,
				vcRMS,
				laRMS,
				lbRMS,
				lcRMS,
				waveChartData: [],
				equipmentType: action.data.equipmentType,
				displayTimestamp: action.data.displayTimestamp,
				event: action.data.event,
				eventList: action.data.eventList,
				syncAccuracy: action.data.syncAccuracy,
				displaySyncAccuracy: action.data.displaySyncAccuracy,
				status: action.data.status,
				connectedStatus: action.data.connectedStatus,
				name: action.data.name,
				serialNumber: action.data.serialNumber,
				secondarySerialNumber: action.data.secondarySerialNumber,
				facilityName: action.data.facilityName,
				sampleFrequency: action.data.sampleFrequency + ' ' + 'Hz',
			};
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);

			return newState;
		}

		case 'LOAD_WAVE_FROM_NEW': {
			const newState = JSON.parse(JSON.stringify(state));
			$('.loaderPosition').addClass('hidden');
			let root_mean_square = function (ary) {
				let sum_of_squares = ary.reduce(function (s, x) {
					return s + x * x;
				}, 0);
				return Math.sqrt(sum_of_squares / ary.length);
			};

			let newharmonicsData = [];
			let harmonicsData = {
				category: [],
				data: [],
			};

			let voltageData = [
				{
					name: 'Va',
					data: [],
				},
				{
					name: 'Vb',
					data: [],
				},
				{
					name: 'Vc',
					data: [],
				},
			];

			let currentData = [
				{
					name: 'Ia',
					data: [],
				},
				{
					name: 'Ib',
					data: [],
				},
				{
					name: 'Ic',
					data: [],
				},
			];

			let fieldCurrentData = [
				{
					name: 'If',
					data: [],
				},
			];

			let fieldVoltageData = [
				{
					name: 'Vf',
					data: [],
				},
			];

			let vaCol = [];
			let vbCol = [];
			let vcCol = [];
			let laCol = [];
			let lbCol = [];
			let lcCol = [];
			let ifCol = [];
			let vfCol = [];
			let scaleValue = 1000;

			$.each(
				action.data.waveform,
				function (index, value) {
					if (value.time !== null && value.time !== undefined) {
						voltageData[0].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vA), color: '#4dac97' },
						});
						voltageData[1].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vB), color: '#EF4123' },
						});
						voltageData[2].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.vC), color: '#4990e2' },
						});

						currentData[0].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iA), color: '#4dac97' },
						});
						currentData[1].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iB), color: '#EF4123' },
						});
						currentData[2].data.push({
							date: parseFloat(value.time * scaleValue),
							yScale: { value: parseFloat(value.iC), color: '#4990e2' },
						});
						if (
							action.data.equipmentType === 'DCMotor' ||
							action.data.equipmentType === 'SynchronousMotor'
						) {
							fieldCurrentData[0].data.push({
								date: parseFloat(value.time * scaleValue),
								yScale: { value: parseFloat(value.iF), color: '#4dac97' },
							});
							fieldVoltageData[0].data.push({
								date: parseFloat(value.time * scaleValue),
								yScale: { value: parseFloat(value.vF), color: '#4dac97' },
							});
							ifCol.push(parseFloat(value.iF));
							vfCol.push(parseFloat(value.vF));
						}

						vaCol.push(parseFloat(value.vA));
						vbCol.push(parseFloat(value.vB));
						vcCol.push(parseFloat(value.vC));
						laCol.push(parseFloat(value.iA));
						lbCol.push(parseFloat(value.iB));
						lcCol.push(parseFloat(value.iC));
					}
				}.bind(this)
			);

			let maxX = 0;
			let vaRMS = Math.round(root_mean_square(vaCol) * 100) / 100;
			let vbRMS = Math.round(root_mean_square(vbCol) * 100) / 100;
			let vcRMS = Math.round(root_mean_square(vcCol) * 100) / 100;
			let laRMS = Math.round(root_mean_square(laCol) * 100) / 100;
			let lbRMS = Math.round(root_mean_square(lbCol) * 100) / 100;
			let lcRMS = Math.round(root_mean_square(lcCol) * 100) / 100;
			let fvRMS = Math.round(root_mean_square(vfCol) * 100) / 100;
			let fcRMS = Math.round(root_mean_square(ifCol) * 100) / 100;

			if (isNaN(fvRMS) || fvRMS === null || fvRMS === undefined) {
				fvRMS = '';
			}
			if (isNaN(fcRMS) || fcRMS === null || fcRMS === undefined) {
				fcRMS = '';
			}
			if (isNaN(vaRMS) || vaRMS === null || vaRMS === undefined) {
				vaRMS = '';
			}
			if (isNaN(vbRMS) || vbRMS === null || vbRMS === undefined) {
				vbRMS = '';
			}
			if (isNaN(vcRMS) || vcRMS === null || vcRMS === undefined) {
				vcRMS = '';
			}
			if (isNaN(laRMS) || laRMS === null || laRMS === undefined) {
				laRMS = '';
			}
			if (isNaN(lbRMS) || lbRMS === null || lbRMS === undefined) {
				lbRMS = '';
			}
			if (isNaN(lcRMS) || lcRMS === null || lcRMS === undefined) {
				lcRMS = '';
			}

			for (let count = 1; count < 1000; count++) {
				let number;
				if (count.toString().length < 2) {
					number = '00' + count;
				} else if (count.toString().length < 3) {
					number = '0' + count;
				} else {
					number = count;
				}

				let key = 'harmonicFreq' + number;
				let value = 'harmonicValue' + number;

				if (action.data.harmonics[key] !== null && action.data.harmonics[key] !== undefined) {
					if (maxX < action.data.harmonics[key]) {
						maxX = action.data.harmonics[key];
					}

					let dataInRequiredChartFormate = {
						x: Math.round(action.data.harmonics[key] * 100) / 100,
						y: action.data.harmonics[value],
						color:
							action.data.harmonics['harmonicColor' + number] === undefined
								? '#4eac97'
								: '#' + action.data.harmonics['harmonicColor' + number],
						harmonicLFMult: action.data.harmonics['harmonicLFMult' + number],
					};

					harmonicsData.data.push(dataInRequiredChartFormate);

					/**
					 * For Temproary purpose
					 */
					newharmonicsData.push({
						date: dataInRequiredChartFormate.x,
						//date: new Date(),
						temperature: {
							value: dataInRequiredChartFormate.y,
							color: dataInRequiredChartFormate.color,
							width: 20,
							harmonicLFMult: dataInRequiredChartFormate.harmonicLFMult,
						},
						width: 20,
					});
				} else {
					break;
				}
			}
			harmonicsData.maxX = maxX;
			harmonicsData.lineFrequencyLocated = Math.round(action.data.lineFrequencyLocated * 100) / 100;

			console.log('harmonicsData', harmonicsData, newharmonicsData);
			console.log('VD', voltageData);
			console.log('CD', currentData);

			newState.waveData = {
				newharmonicsData,
				harmonicsData,
				voltageData,
				currentData,
				fieldCurrentData,
				fieldVoltageData,
				fvRMS,
				fcRMS,
				vaRMS,
				vbRMS,
				vcRMS,
				laRMS,
				lbRMS,
				lcRMS,
				waveChartData: [],
				equipmentType: action.data.equipmentType,
				displayTimestamp: action.data.displayTimestamp,
				event: action.data.event,
				syncAccuracy: action.data.syncAccuracy,
				displaySyncAccuracy: action.data.displaySyncAccuracy,
				status: action.data.status,
				connectedStatus: action.data.connectedStatus,
				name: action.data.name,
				serialNumber: action.data.serialNumber,
				secondarySerialNumber: action.data.secondarySerialNumber,
				facilityName: action.data.facilityName,
				sampleFrequency: action.data.sampleFrequency + ' ' + 'Hz',
			};
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);

			console.log('sasasasasas', newState);
			return newState;
		}

		case 'LOAD_BLANK_TREND_FROM_NEW': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendData.chartData = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendData.timeZone = action.data.timezone;
			newState.trendData.name = action.data.name;
			newState.trendData.serialNumber = action.data.serialNumber;
			newState.trendData.facilityName = action.data.facilityName;
			newState.trendData.secondarySerialNumber = action.data.secondarySerialNumber;
			newState.trendData.connectedStatus = action.data.connectedStatus;
			newState.trendData.equipmentType = action.data.equipmentType;
			newState.trendData.dataPointIntervalMinutes = action.data.dataPointIntervalMinutes + ' ' + 'min';
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 200);

			return newState;
		}

		case 'LOAD_TREND_FROM_FOR_SUBNODE': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendData.chartData = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendData.timeZone = action.data.timezone;
			newState.trendData.dataPointIntervalMinutes = action.data.dataPointIntervalMinutes + ' ' + 'min';
			newState.comingSource = 'subNodePage';
			newState.selectedInfo = action.channelData.name;
			newState.trendData.name = action.data.channelName;
			newState.trendData.serialNumber = action.data.serialNumber;
			newState.trendData.secondarySerialNumber = action.data.secondarySerialNumber;
			newState.trendData.connectedStatus = action.data.connectedStatus;
			newState.trendData.facilityName = action.data.facilityName;
			// newState.trendData.equipmentType = action.data.equipmentType;
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 200);
			return newState;
		}

		case 'LOAD_TREND_FOR_SUBNODE_MAXDAYS': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendData.chartData = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.comingSource = 'subNodePage';
			newState.trendData.timeZone = action.data.timezone;
			newState.trendData.dataPointIntervalMinutes = action.data.dataPointIntervalMinutes + ' ' + 'min';
			newState.changed = 5;
			newState.trendData.name = action.data.channelName;
			newState.trendData.serialNumber = action.data.serialNumber;
			newState.trendData.secondarySerialNumber = action.data.secondarySerialNumber;
			newState.trendData.connectedStatus = action.data.connectedStatus;
			newState.trendData.facilityName = action.data.facilityName;
			// newState.trendData.equipmentType = action.data.equipmentType;
			return newState;
		}

		case 'CLEAR_SUBNODE_CONDITION': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.comingSource = '';
			newState.trendData = {};
			return newState;
		}

		case 'LOAD_TREND_FROM_NEW': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendData.chartData = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendData.timeZone = action.data.timezone;
			newState.trendData.name = action.data.name;
			newState.trendData.serialNumber = action.data.serialNumber;
			newState.trendData.secondarySerialNumber = action.data.secondarySerialNumber;
			newState.trendData.connectedStatus = action.data.connectedStatus;
			newState.trendData.facilityName = action.data.facilityName;
			newState.trendData.dataPointIntervalMinutes = action.data.dataPointIntervalMinutes + ' ' + 'min';
			newState.trendData.equipmentType = action.data.equipmentType;
			newState.changed = 5;
			return newState;
		}

		case 'LOAD_TREND_POWER_METER_GRAPH': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.chartLoaded = true;
			return newState;
		}

		case 'LOAD_TREND_POWER_METER_GRAPH_Full': {
			const newState = JSON.parse(JSON.stringify(state));
			return newState;
		}

		case 'MAKE_CHART_LOADED': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.chartLoaded = false;
			return newState;
		}

		case 'LOAD_TREND_FROM1': {
			console.log('Action', action);
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendDataOnDemand = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendDataOnDemand.timeZone = action.data.timezone;
			newState.changed = 1;
			console.log('trendDataOnDemand', newState.trendDataOnDemand);
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);
			return newState;
		}

		case 'LOAD_TREND_FROM2': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendDataOnDemand = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendDataOnDemand.timeZone = action.data.timezone;
			newState.changed = 2;
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);

			return newState;
		}

		case 'INTERVAL': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.allInterval.push(action.data);

			return newState;
		}

		case 'CLEAR_INTERVAL': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.allInterval = [];

			return newState;
		}

		case 'LOAD_TREND_FROM3': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendDataOnDemand = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendData.historyFieldList = historyFieldList;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.trendDataOnDemand.timeZone = action.data.timezone;
			newState.changed = 3;
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);
			return newState;
		}

		case 'LOAD_TREND_FROM4': {
			const newState = JSON.parse(JSON.stringify(state));
			let historyFieldList = [];
			if (action.data.historyFieldList.length > 0) {
				action.data.historyFieldList.forEach((tredhistory) => {
					historyFieldList.push({
						name: tredhistory.displayFieldName,
						value: tredhistory.fieldName,
					});
				});
			}
			newState.trendDataOnDemand = processDataOfTrandAnalysisWithD3(
				action.data.dataPointInterval,
				action.data.history,
				action.dataPoint,
				historyFieldList
			);
			newState.trendDataOnDemand.timeZone = action.data.timezone;
			newState.trendData.displayTimestampLatest = action.data.displayTimestampLatest;
			newState.changed = 4;
			newState.trendData.historyFieldList = historyFieldList;
			setTimeout(function () {
				$('.loaderPosition').addClass('hidden');
			}, 500);

			return newState;
		}

		case 'ON_ERROR_OCCURRED': {
			const newState = JSON.parse(JSON.stringify(state));
			newState.dispalyMessage = action.error;

			console.log('Error', action);
			return newState;
		}

		case 'PASSWORD_SET_SUCCESS': {
			setTimeout(function () {
				//hashHistory.push('/login');
			}, 3000);
			return state;
		}

		default:
			return state;
	}
}

function processDataOfTrandAnalysisWithD3(dataPointInterval, data, dataPoint, trendFields) {
	console.log('processDataOfTrandAnalysisWithD3 init', dataPointInterval, data, dataPoint, trendFields);
	let processedData = {};
	data = data.reverse();
	trendFields.forEach((dataPointValue) => {
		processedData[dataPointValue.value] = [];
	});
	const dataLength = data.length;
	if (data && data.length > 0) {
		for (let objData in processedData) {
			processedData[objData].mainPoints = [];
			processedData[objData].missingPoints = [];

			data.forEach((eachRecord, index) => {
				if (!eachRecord[objData]) {
					eachRecord[objData] = 0;
				}

				processedData[objData].mainPoints.push({
					date: new Date(parseInt(eachRecord.timestamp) * 1000),
					[objData]: { value: eachRecord[objData] },
				});
				processedData[objData].missingPoints.push({
					date: new Date(parseInt(eachRecord.timestamp) * 1000),
					[objData]: {
						color: 'white',
						nullify: true,
						value: eachRecord[objData],
					},
				});

				if (index < dataLength - 1) {
					const missingPointsCount = parseInt(
						(data[index + 1].timestamp - eachRecord.timestamp) / dataPointInterval
					);

					if (missingPointsCount > 1) {
						for (let i = 1; i < missingPointsCount; i++) {
							processedData[objData].mainPoints.push({
								date: new Date(parseInt(eachRecord.timestamp + i * dataPointInterval) * 1000),
								[objData]: { value: eachRecord[objData] },
							});
							processedData[objData].missingPoints.push({
								date: new Date(parseInt(eachRecord.timestamp + i * dataPointInterval) * 1000),
								[objData]: {
									color: 'white',
									nullify: false,
									value: eachRecord[objData],
								},
							});
						}
					}
				}
			});
		}
	}
	console.log('processedData>>>>>>>>>>>>>>>>>>>>>', processedData, dataPoint);
	processedData.id = Math.floor(Math.random() * 100) + 1;
	return processedData;
}
