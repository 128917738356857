import $ from 'jquery';
import config from './config';

class waveApi {

    static getWaveFormData(token, serialNumber){

        return new Promise((resolve, reject) => {

            window.requestGetWaveFormData = $.ajax({
                url: config.baseURL + "locations/" + serialNumber + "/waveform",
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${token}`
                },
                dataType: "json"
            });

            window.requestGetWaveFormData.done(function (msg) {
                resolve(Object.assign({}, msg.data));
            });

            window.requestGetWaveFormData.fail(function (jqXHR, textStatus) {
                resolve(Object.assign([], {errorMessage: jqXHR.statusText}));
            });
        });
    }

}

export default waveApi;