import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as waveFormAction from '../../actions/waveForm';
import * as viewPowerMeteringAction from '../../actions/viewPowerMetering';
import * as logOffAction from '../../actions/logOffAction';
import TopNavigation from '../common/topNavigation';
import SecTopHeader from '../common/SecTopHeader';
import BuildLineChartWave from '../../chart/line_wave';
import BuildBarChart from '../../chart/bar';
import Options from '../common/more_options';
import config from '../../api/config';

var chartOne = null,
	chartTwo = null,
	chartThree = null,
	chartFour = null,
	chartFive = null;
class WaveFormPageNew extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			event: '',
		};
		if (this.props.waveData.displayTimestamp === undefined) {
			this.props.actionWaveForm.RedirectToEquipmentPortfolio();
		}
		this.state = {
			showOptions: false,
		};
	}

	toggleOptions() {
		this.setState({ showOptions: !this.state.showOptions });
	}

	componentDidMount() {
		if (this.props.token === '') {
			this.props.history.push('/equipment-portfolio');
		}
		if (this.props.waveData) {
			this._allChartRenderer();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		console.log('componentDidUpdate', prevState, this.state, prevProps.waveData);
		if (JSON.stringify(prevProps.waveData) !== JSON.stringify(this.props.waveData)) {
			setTimeout(() => this._allChartRenderer(), 0);
		}
	}

	getUpdatedLink() {
        fetch(config.baseURL + "sessions", {
            method: 'GET',
            headers: new Headers({
                'Authorization': `Bearer ${this.props.token}`
            })
        }).then(response => {
            console.log(response)
            response.json()
                .then(data => {
                    window.location.href = (data.data.featureList[0].value)
                })
        })
    }

	_allChartRenderer = () => {
		console.log('xxxxxxxx - ddssd-xxxxxxxx', this.props.waveData);
		this._chartRenderer(
			this.props.waveData.voltageData.map((d) => d.data),
			'yScale',
			'firstChart'
		);
		this._chartRenderer(
			this.props.waveData.currentData.map((d) => d.data),
			'yScale',
			'secondChart'
		);


		let fieldVoltageData = this.props.waveData.fieldVoltageData.map((d) => d.data);
		fieldVoltageData.push(
			JSON.parse(JSON.stringify(this.props.waveData.fieldVoltageData)).map((d) => {
				return d.data.map((dd) => {
					dd.yScale.value = 0;
					dd.yScale.color = '#707073';
					return dd;
				});
			})[0]
		);

		let fieldCurrentData = this.props.waveData.fieldCurrentData.map((d) => d.data);
		fieldCurrentData.push(
			JSON.parse(JSON.stringify(this.props.waveData.fieldCurrentData)).map((d) => {
				return d.data.map((dd) => {
					dd.yScale.value = 0;
					dd.yScale.color = '#707073';
					return dd;
				});
			})[0]
		);

		if (
			this.props.waveData.equipmentType === 'DCMotor' ||
			this.props.waveData.equipmentType === 'SynchronousMotor'
		) {
			this._chartRenderer(fieldVoltageData, 'yScale', 'thirdChart');
			this._chartRenderer(fieldCurrentData, 'yScale', 'fourthChart');
		}
	};

	_chartRenderer = (seriesData, yDataAttribute, chartName) => {
		console.log('_chartRenderer', seriesData, yDataAttribute);
		const customChartControlData = {
			scaleType: 'linear',
			color: '',
			topTooltipLabel: 'Time',
			bottomTooltipLabel: '',
			yaxislabel: '',

			rangeButtons: [
				{ buttonName: '5ms', buttonText: '5ms', passValue: 1, xposition: 20, rectSize: 30 },
				{ buttonName: '10ms', buttonText: '10ms', passValue: 2, xposition: 60, rectSize: 40 },
				{ buttonName: '25ms', buttonText: '25ms', passValue: 3, xposition: 110, rectSize: 45 },
				{ buttonName: '50ms', buttonText: '50ms', passValue: 4, xposition: 160, rectSize: 40 },
				{ buttonName: 'all', buttonText: 'All', passValue: 4, xposition: 210, rectSize: 20 },
			],
		};
		if (chartName === 'firstChart') {
			customChartControlData.bottomTooltipLabel = 'Va';
			customChartControlData.bottomTooltipLabel2 = 'Vb';
			customChartControlData.bottomTooltipLabel3 = 'Vc';
			customChartControlData.yaxislabel = 'Volts';
			customChartControlData.xaxislabel = 'Time (ms)';
			chartOne = BuildLineChartWave(
				{ series: seriesData, xDataAttribute: 'date', yDataAttribute: yDataAttribute },
				'#main-chart-first-waveform',
				'#navigator-chart-new-version',
				customChartControlData,
				function () {
					return [
						chartTwo && chartTwo.__getChartInstance(),
						chartThree && chartThree.__getChartInstance(),
						chartFour && chartFour.__getChartInstance(),
					];
				},
				0,
				0
			);
		}

		if (chartName === 'secondChart') {
			customChartControlData.bottomTooltipLabel = 'Ia';
			customChartControlData.bottomTooltipLabel2 = 'Ib';
			customChartControlData.bottomTooltipLabel3 = 'Ic';
			customChartControlData.yaxislabel = 'Amps';
			customChartControlData.xaxislabel = 'Time (ms)';
			chartTwo = BuildLineChartWave(
				{ series: seriesData, xDataAttribute: 'date', yDataAttribute: yDataAttribute },
				'#main-chart-second-waveform',
				'#navigator-chart',
				customChartControlData,
				function () {
					return [
						chartOne && chartOne.__getChartInstance(),
						chartThree && chartThree.__getChartInstance(),
						chartFour && chartFour.__getChartInstance(),
					];
				},
				0,
				0
			);
		}

		if (chartName === 'thirdChart') {
			customChartControlData.bottomTooltipLabel = 'Vf';
			customChartControlData.yaxislabel = 'Volts';
			customChartControlData.xaxislabel = 'Time (ms)';
			chartThree = BuildLineChartWave(
				{ series: seriesData, xDataAttribute: 'date', yDataAttribute: yDataAttribute },
				'#main-chart-three-waveform',
				'#navigator-chart-trending',
				customChartControlData,
				function () {
					return [
						chartOne && chartOne.__getChartInstance(),
						chartTwo && chartTwo.__getChartInstance(),
						chartFour && chartFour.__getChartInstance(),
					];
				},
				0,
				0
			);
		}

		if (chartName === 'fourthChart') {
			customChartControlData.bottomTooltipLabel = 'If';
			customChartControlData.yaxislabel = 'Amps';
			customChartControlData.xaxislabel = 'Time (ms)';
			chartFour = BuildLineChartWave(
				{ series: seriesData, xDataAttribute: 'date', yDataAttribute: yDataAttribute },
				'#main-chart-four-waveform',
				'#navigator-chart-one',
				customChartControlData,
				function () {
					return [
						chartOne && chartOne.__getChartInstance(),
						chartTwo && chartTwo.__getChartInstance(),
						chartThree && chartThree.__getChartInstance(),
					];
				},
				0,
				0
			);
		}
	};

	logOff = () => {
		try {
			window.requestGetWaveFormData.abort();
		} catch (e) {
			console.log('Error while window.requestGetWaveFormData.abort');
		}
		this.props.logOffAction.logOut();
	};


	render() {
		console.log('this.props.waveData', this.props.waveData);
		console.log('lineChart');

		const {
			connectedStatus,
			displaySyncAccuracy,
			equipmentType,
			event,
			displayTimestamp,
			name,
			serialNumber,
			secondarySerialNumber,
			facilityName,
			status,
			sampleFrequency,
			vaRMS,
			vbRMS,
			vcRMS,
			laRMS,
			lbRMS,
			lcRMS,
			fvRMS,
			fcRMS,
			harmonicsData,
		} = this.props.waveData;
		// console.log("eventListeventListeventList", equipmentType, eventList, event);
		return (
			<div>
				{displayTimestamp !== undefined && (
					<div className='reportPage'>
						<div className='wrapperHW'>
							<div className='wrapperH paddingZero'>
								<div className='loginH clearfix'>
									<div className='logosmallH'>
										<a href='#'>&nbsp;</a>
									</div>
									<div className='logoff '>
										<div className='three_line' onClick={this.toggleOptions.bind(this)}>
											&nbsp;
										</div>
									</div>
								</div>
								<div>
									{this.state.showOptions ? (
										<div className='options_menu_waveform_page'>
											<Options />
										</div>
									) : null}
								</div>
								<TopNavigation activeNode={3} />
							</div>
							<SecTopHeader
								name={name}
								serialNumber={serialNumber}
								secondarySerialNumber={secondarySerialNumber}
								facilityName={facilityName}
							/>
							<div className='clearfix'>
								<div className='blueBoxH bdRadius spmb30'>
									<div className='titleH clearfix'>
										<div className='subTitleH'>
											<span className='waveHdCenter dbBL allCaps flNone'>Waveforms</span>
											<span className='dbBL flNone nodeStatusFS'>
												NODE STATUS: {connectedStatus === 'OFFLINE' ? 'Not Cloud Connected' : 'Cloud Connected'}
											</span>
										</div>
										<div className='dateTimeH'>
											<span className='waveHdRight waveHdRightStyleInfo'>
												<span>
													<span className='spldbBL'>updated: </span> {displayTimestamp}
												</span>

												<span className='waveStatus spldbBL'>
													{'sampled:' + ' ' + sampleFrequency}
												</span>
												{(equipmentType === 'DCMotor' ||
													equipmentType === 'SynchronousMotor') && (
													<span className='waveStatus spldbBL'>
														{'synch accuracy:' + ' ' + displaySyncAccuracy}
													</span>
												)}
											</span>
										</div>
									</div>
									
				
									{/*
                                    D-3 charts starts from here
                                    */}

									<div className='clearfix'>
										<div id='synchronizationContainer'>
											<div className='chartContainerMain bdRadius'>
                                                <div className='chartHeader'>
                                                <span className='waveSN plotSNTitle'>
														S/N: {serialNumber}{' '}
													</span>
													Voltage <span className='waveRMP'>(RMS)</span>{' '}
													<span className='vaColor' id='vaValue'>
														Va={vaRMS},{' '}
													</span>
													<span className='vbColor' id='vbValue'>
														Vb={vbRMS},{' '}
													</span>
													<span className='vcColor' id='vcValue'>
														Vc={vcRMS}{' '}
													</span>
													<span className='waveRMPA ml6 vcValueE'>across 50ms range</span>
												</div>
												<div id='main-chart-first-waveform' className='main-chart-height' />
												<div
													id='navigator-chart-new-version'
													className='navigator-main-chart-height'
												/>
											</div>
											<div className='chartContainerMain bdRadius'>
                                                <div className='chartHeader'>
                                                <span className='waveSN plotSNTitle'>
														S/N:{serialNumber}{' '}
													</span>
													Current <span className='waveRMP'>(RMS)</span>{' '}
													<span className='vaColor' id='laValue'>
														Ia={laRMS},{' '}
													</span>
													<span className='vbColor' id='lbValue'>
														Ib={lbRMS},{' '}
													</span>
													<span className='vcColor' id='lcValue'>
														Ic={lcRMS}
													</span>
													<span className='waveRMPA ml6 vcValueE'>across 50ms range</span>
												</div>
												<div id='main-chart-second-waveform' className='main-chart-height' />
												<div id='navigator-chart' className='navigator-second-chart-height' />
											</div>
											{(equipmentType === 'DCMotor' || equipmentType === 'SynchronousMotor') && (
												<div className='chartContainerMain bdRadius'>
                                                    <div className='chartHeader'>
                                                    <span className='waveSN plotSNTitle'>
														S/N: {secondarySerialNumber}{' '}
													</span>
														Field Voltage<span className='waveRMP'>(RMS)</span>{' '}
														<span className='vfColor' id='fvValue'>
															Vf={fvRMS}
														</span>
														<span className='waveRMPA ml6 vcValueE'>across 50ms range</span>
													</div>
													<div id='main-chart-three-waveform' className='main-chart-height' />
													<div
														id='navigator-chart-trending'
														className='navigator-main-chart-height'
													/>
												</div>
											)}
											{(equipmentType === 'DCMotor' || equipmentType === 'SynchronousMotor') && (
												<div className='chartContainerMain bdRadius'>
                                                    <div className='chartHeader'>
                                                    <span className='waveSN plotSNTitle'>
														S/N: {secondarySerialNumber}{' '}
													</span>
														Field Current{' '}
														<span className='ifColor' id='fcValue'>
															If={fcRMS}
														</span>
														<span className='waveRMPA ml6 vcValueE'>across 50ms range</span>
													</div>
													<div id='main-chart-four-waveform' className='main-chart-height' />
													<div
														id='navigator-chart-one'
														className='navigator-main-chart-height'
													/>
												</div>
											)}
										</div>
										{event === 'Real Time' && (
											<div className='chartContainerMain bdRadius' style={{'position':'relative'}}>
												<img src='/harmonic-blur.png' style={{'width': '100%', 'height':'100%'}}/>
												<div style={{
													'position':'absolute',
													'top': '50%',
													'left': '50%',
													'transform': 'translate(-50%, -50%)',
													'fontSize': '20px'
													}}>
													{"Harmonic Data has moved to "}                            
													<a href="javascript:void(0);" onClick={this.getUpdatedLink.bind(this)} className="analyticsLink_font desktop_size">
													Volta Analytics
													</a>
												</div>
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		waveData: state.application.waveData,
		token: state.application.token,
		selectedName: state.application.selectedName,
		selectedSerialNumber: state.application.selectedSerialNumber,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actionWaveForm: bindActionCreators(waveFormAction, dispatch),
		actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
		logOffAction: bindActionCreators(logOffAction, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(WaveFormPageNew);
