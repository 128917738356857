import React from 'react';
import $ from 'jquery';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as viewPowerMeteringAction from '../../actions/viewPowerMetering';
import * as viewEquipmentPortfolio from '../../actions/viewEquipmentPortfolio';
import * as viewMoterAnalysis from '../../actions/viewMotorAnalysis';
import * as viewWaveForm from '../../actions/waveForm';
import NumericLable from 'react-pretty-numbers';
import TopNavigation from '../common/topNavigation';
import config from '../../api/config';
import * as logOffAction from '../../actions/logOffAction';
import SecTopHeader from '../common/SecTopHeader';
import Excitation from '../common/ExcitationComponent';
import Options from '../common/more_options';

class PowerMeterPage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			showOptions: false,
		};
	}

	toggleOptions() {
		this.setState({ showOptions: !this.state.showOptions });
	}

	componentDidMount() {
		this.interval = setInterval(this.refreshPage, 2000);
		setTimeout(function () {
			$('.loaderPosition').addClass('hidden');
		}, 500);
	}

	componentWillUnmount() {
		clearInterval(this.interval);
	}

	refreshPage = () => {
		this.props.actionViewPowerMetering.loadPowerMetering(undefined);
	};

	applyClassName = (name) => {
		if (name !== undefined && name.length > 15) {
			return 'eqType eqTypesplmar';
		} else {
			return 'eqType ';
		}
	};

	logOff = () => {
		this.props.logOffAction.logOut();
	};

	render() {
		console.log('this.props.metering', this.props.metering);
		let option = {
			justification: 'R',
			locales: 'en-AU',
			percentage: false,
			precision: config.datapoint_precision,
			wholenumber: null,
			commafy: true,
			title: true,
		};

		let optionCeil = {
			justification: 'R',
			locales: 'en-AU',
			percentage: false,
			precision: config.datapoint_precision,
			commafy: true,
			title: true,
			wholenumber: 'ceil',
		};

		return (
			<div className='reportPage'>
				<div className='wrapperH'>
					<div className='loginH clearfix'>
						<div className='logosmallH'>
							<a href='#'>&nbsp;</a>
						</div>
						<div className='logoff '>
							<div className='three_line' onClick={this.toggleOptions.bind(this)}>
								&nbsp;
							</div>
						</div>
					</div>

					<div className='clearfix'>
						<div>
							{this.state.showOptions ? (
								<div className='options_menu'>
									<Options />
								</div>
							) : null}
						</div>

						<TopNavigation activeNode={2} />
						<SecTopHeader
							name={this.props.metering.name}
							serialNumber={this.props.metering.serialNumber}
							facilityName={this.props.metering.facilityName}
						/>
						<div className='blueBoxH bdRadius'>
							<div className='titleH text-center clearfix'>
								{/*<div className="logosmallH">
                                    <div className="seperateLine">POWER METERING</div>
                                    <div className="seperateLine font18">STATUS : {this.props.metering.status}</div>
                                </div>
                                <div className="dataH">{this.props.metering.displayTimestamp}</div>*/}

								<div className='subTitleH text-center'>
									<span className='dbBL allCaps flNone txt-center'>POWER METERING</span>
									<span className='dbBL flNone nodeStatusFS txt-center'>
										NODE STATUS :{' '}
										{this.props.metering.connectedStatus === 'OFFLINE'
											? 'Not Cloud Connected'
											: 'Cloud Connected'}
									</span>
								</div>
								<div className='dateTimeH'>
									<span className='waveHdRight'>
										<span className='spldbBL'>updated: </span>{' '}
										{this.props.metering.displayTimestamp}
									</span>
								</div>
							</div>

							<div className='clearfix'>
								<div className='specialWrapper'>
									<div className='meterH'>
										<div className='padLefRigH spPb40'>
											<div className='rectH'>
												<ul>
													<li>
														<span className='rectHead minMl50'>Voltage</span>
														<span className='goldBox dbi splBdRadius'>
															{this.props.metering.status === 'ON' ? (
																<span className='greenTxt splPl1 minWid75'>
																	<NumericLable params={option}>
																		{this.props.metering.voltage}
																	</NumericLable>
																</span>
															) : (
																<span className='greenTxt splPl1 minWid75'>
																	<NumericLable params={option}>{'---'}</NumericLable>
																</span>
															)}

															<span className='perSign wid26'>Volts</span>
														</span>
													</li>
													<li>
														<span className='rectHead minMl10'>Current</span>
														<span className='goldBox dbi splBdRadius'>
															{this.props.metering.status === 'ON' ? (
																<span className='greenTxt splPl1 minWid75'>
																	<NumericLable params={option}>
																		{this.props.metering.current}
																	</NumericLable>
																</span>
															) : (
																<span className='greenTxt splPl1 minWid75 minWid65'>
																	<NumericLable params={option}>{'---'}</NumericLable>
																</span>
															)}

															<span className='perSign wid26'>Amps</span>
														</span>
													</li>
													<li>
														<span className='rectHead minMl10'>HP</span>
														<span className='goldBox dbi splBdRadius'>
															{this.props.metering.status === 'ON' ? (
																<span className='greenTxt splPl1 minWid75'>
																	<NumericLable params={option}>
																		{this.props.metering.hp}
																	</NumericLable>
																</span>
															) : (
																<span className='greenTxt splPl1 minWid75 minWid65'>
																	<NumericLable params={option}>{'---'}</NumericLable>
																</span>
															)}

															<span className='perSign wid26'>HP</span>
														</span>
													</li>
													{this.props.metering.equipmentType !== 'DCMotor' ? (
														<li>
															<span className='rectHead minMl50'>Frequency</span>
															<span className='goldBox dbi splBdRadius '>
																{this.props.metering.status === 'ON' ? (
																	<span className='greenTxt splPl1 minWid75'>
																		<NumericLable params={option}>
																			{this.props.metering.lineFrequency}
																		</NumericLable>
																	</span>
																) : (
																	<span className='greenTxt splPl1 minWid75'>
																		<NumericLable params={option}>
																			{'---'}
																		</NumericLable>
																	</span>
																)}

																<span className='perSign wid26'>Hz</span>
															</span>
														</li>
													) : (
														''
													)}
													{this.props.metering.facilityPortfolio?.id == "63e2bfc8abdd8e287378e0ed" ? (
														<li>
															<span className='rectHead minMl50'>Power Factor</span>
																<span className='goldBox dbi splBdRadius '>
																	{this.props.metering.status === 'ON' ? (
																		<span className='greenTxt splPl1 minWid75'>
																			<NumericLable params={option}>
																				{this.props.metering.powerFactor}
																			</NumericLable>
																		</span>
																	) : (
																		<span className='greenTxt splPl1 minWid75'>
																			<NumericLable params={option}>
																				{'---'}
																			</NumericLable>
																		</span>
																	)}
																<span className='perSign wid26'></span>
															</span>
														</li>
													) : (
														''
													)}
												</ul>
											</div>
											<div className='circleH'>
												<div className='firstCir'>
													<div className='secCir'>
														<div className='thirdCir'>
															<div className='divposition'>
																<div
																	className={this.applyClassName(
																		this.props.metering.displayEquipmentType
																	)}>
																	{' '}
																	{this.props.metering.displayEquipmentType !==
																	undefined
																		? this.props.metering.displayEquipmentType
																		: ''}
																</div>

																{this.props.metering.status === 'ON' ? (
																	<span className='goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading'>
																		<span className='dis'>
																			<NumericLable params={option}>
																				{(
																					this.props.metering.power / 1000
																				).toFixed(config.datapoint_precision)}
																			</NumericLable>
																		</span>
																		<span className='kwFont'>kW</span>
																	</span>
																) : (
																	<span className='goldBoxforInsideCircle splRadisForgoldBoxInsideCircle voltageFont mainPowerReading'>
																		<span className='dis'>
																			<NumericLable params={option}>
																				{'---'}
																			</NumericLable>
																		</span>
																		<span className='kwFont'>kW</span>
																	</span>
																)}
																<div className='circlecontent'>
																	<span className='hourH'>
																		<NumericLable params={optionCeil}>
																			{this.props.metering.runningTimeDays}
																		</NumericLable>
																	</span>
																	<span className='hrsTxt'> Days</span>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					{this.props.metering.equipmentType !== undefined &&
						(this.props.metering.equipmentType === 'SynchronousMotor' ||
							this.props.metering.equipmentType === 'DCMotor') && (
							<Excitation
								showContent='SyncPowerMeter'
								fieldCurrent={this.props.metering.fieldCurrent}
								fieldVoltage={this.props.metering.fieldVoltage}
							/>
						)}
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		metering:
			state.application.metering.status !== undefined && state.application.metering.status === 'ON'
				? state.application.metering
				: {
						name: state.application.metering.name,
						timezone: 'America/Los_Angeles',
						serialNumber: state.application.metering.serialNumber,
						status:
							state.application.metering.status === 'STARTUP'
								? 'CONNECTING'
								: state.application.metering.status,
						voltage: '',
						current: '',
						lineFrequency: '',
						hp: '',
						runningTimeDays: '',
						timestamp: 1493246823.657,
				  },
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		actionViewEquipmentPortfolio: bindActionCreators(viewEquipmentPortfolio, dispatch),
		actionViewPowerMetering: bindActionCreators(viewPowerMeteringAction, dispatch),
		actionViewMotorAnalysis: bindActionCreators(viewMoterAnalysis, dispatch),
		logOffAction: bindActionCreators(logOffAction, dispatch),
		actionWaveFrom: bindActionCreators(viewWaveForm, dispatch),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PowerMeterPage);
